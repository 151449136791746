import * as React from 'react';
import Login from './pages/Login';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Layout from './components/Layout';
import Codes from './components/codes/Codes';
import Students from './components/students/Students';
import Audience from './components/audience/Audience';
import Universities from './components/universities/Universities';
import { useCookies } from 'react-cookie';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import Turbo from './components/codes copy/Turbo';
import Question from './components/codes/Question';
// import Audience from './components/codes/Audience';

export default function App() {


    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const navigate = useNavigate();

    React.useEffect(() => {
        const cookie = cookies['token'];
        if (!cookie) {
            navigate('/login', {replace: true});
        }
    }, [])

    // React.useEffect(() => {
    //     const token = cookies['token']
    //     const fetch = async () => {
    //         try {
    //             const response = await axios.post(`${process.env.REACT_APP_URL}/houdix/seen/admin/auth`, null, {
    //                 headers: {
    //                     'Authorization': `Bearer ${token}`
    //                 }
    //             });
    //             if (response.status === 200) {
    //                 return
    //             }
    //         } catch (error) {
    //             if (error) {
    //                 navigate('/login', { replace: true });
    //             }
    //         }
    //     };
    //     fetch();
    // }, []);

    return (
        <MathJaxContext>
            {/* <MathJax>{"when \\(a \\ne 0\\), there are tow solutions to \\(ax^2 + bx + c = 0\\) and the are \\( x = { -b \\pm \\sqrt{b^2-4ac} \\over 2a}. \\)"}</MathJax> */}
            <Routes>
                <Route path="/" element={(<Layout />)}>
                    <Route path="" element={<Students />} />
                    <Route path="/adversment" element={<Universities />} />
                    {/* <Route path="/questions" element={<Questions />} /> */}
                    <Route path="/questions" element={<Codes />} />
                    <Route path="/turbo" element={<Turbo />} />
                    <Route path="/audience" element={<Audience />} />
                    <Route path="/questions/:id" element={<Question />} />
                </Route>
                <Route path="/login" element={<Login />} />
            </Routes>
        </MathJaxContext>
    );
}