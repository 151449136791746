import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import '../inputs.css';
import { fetchProvinces } from '../../helpers/public';
import { CircularProgress } from '@mui/material';
import { useCookies } from 'react-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: '50px',
    textAlign: 'center',
    boxShadow: 24,
    p: '50px 20px',
    fontFamily: 'Almarai',
};

export default function Transfer({ handleClose, handleClickAlert, selectedRows }) {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const [isLoading, setIsLoading] = React.useState(false);

    const [formData, setFormData] = useState({
        image: null,
        name: ""
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange1 = (e) => {
        setFormData({
            ...formData,
            image: e.target.files[0],
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFormSubmit = async (e) => {
        const token = cookies['token'];
        setIsLoading(true);
        e.preventDefault();
        const form = new FormData();
        form.append('image', formData.image);
        form.append('name', formData.name);
        console.log(form);
        setIsSubmitting(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/categories`, form, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setIsLoading(false);
                handleClose()
                handleClickAlert()
            }
        } catch (error) {
            console.error('Error posting university:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!isLoading ? (
                        <>
                            <Typography sx={{ fontFamily: 'Almarai' }} id="modal-modal-title" variant="h3" component="h2">
                                اضافة صنف
                            </Typography>
                            <div class="center">
                                <form onSubmit={handleFormSubmit}>
                                    <div class="inputbox">
                                        <input
                                            dir='rtl'
                                            type="text"
                                            name="name"
                                            // value={formData.subject_name}
                                            onChange={handleInputChange}
                                            required
                                            autoFocus
                                        />
                                        <span>الاسم</span>
                                    </div>
                                    <div className="inputbox">
                                        <input
                                            type="file"
                                            name="image"
                                            onChange={handleInputChange1}
                                            required
                                        />
                                    </div>
                                    <div class="inputbox">
                                        <button type="submit" disabled={isSubmitting}>
                                            موافق
                                        </button>
                                        <button type="button" onClick={handleClose}>
                                            إلغاء
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', height: "300px", justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}