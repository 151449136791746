import { Link } from "react-router-dom";

//* for years page
export const yearsDataGrid = (formatCreatedAt, ModeEditIcon, handleEditActionClick, CircleIcon) => {
    return [
        // { field: 'id', headerName: 'ID', width: 150, headerClassName: 'header-cell' },
        { field: 'year_name', headerName: 'السنة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'collage.collage_name', headerName: 'الكلية', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'collage.university.university_name', headerName: 'الجامعة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'collage.university.university_state', headerName: 'المحافظة', flex: 0.1, headerClassName: 'header-cell' },
        // { field: 'createdAt', headerName: 'تاريخ الإنشاء', flex: 0.1, headerClassName: 'header-cell', valueFormatter: formatCreatedAt, },
        {
            field: 'is_root', headerName: 'مفعل', flex: 0.1, headerClassName: 'header-cell', renderCell: (params) => (
                params.row.is_root === 1 ? <div className='table-icon' style={{ backgroundColor: "#4caf50" }}>
                    <CircleIcon className='iconTable' o />
                </div> : <div className='table-icon' style={{ backgroundColor: "#f44336" }}>
                    <CircleIcon className='iconTable' />
                </div>
            )
        },
        {
            field: 'actions', headerName: 'Actions', flex: 0.1, renderCell: (params) => (
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
    ];
}
//* for university page
export const universityDataGrid = (formatCreatedAt, ModeEditIcon, handleEditActionClick, handleShowActionClick) => {
    return [
        { field: 'id', headerName: 'ID', flex: 0.2, headerClassName: 'header-cell' },
        { field: 'description', headerName: 'تفاصيل', flex: 0.2, headerClassName: 'header-cell' },
        {
            field: 'path', headerName: 'الفديو', flex: 0.1, renderCell: (params) => (
                // <div className='table-image'>
                //     {/* <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} /> */}
                //     <img style={{ width: "100%", cursor: "pointer" }} src={`${process.env.REACT_APP_URL}/${params.row.image}`} onClick={() => handleShowActionClick(params.row)} alt="" />
                // </div>
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleShowActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
        {
            field: 'actions', headerName: 'Actions', flex: 0.2, renderCell: (params) => (
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
    ];
}
//* for subject page
export const subjectDataGrid = (formatCreatedAt, ModeEditIcon, SortIcon, handleShowActionClick, handleEditActionClick) => {
    return [
        { field: 'subject_name', headerName: 'المادة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject_notes', headerName: 'ملاحظة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'year.year_name', headerName: 'السنة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'year.collage.collage_name', headerName: 'الكلية', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'year.collage.university.university_name', headerName: 'الجامعة', width: 150, headerClassName: 'header-cell' },
        { field: 'bachelor.bachelor_name', headerName: 'الفرع', flex: 0.1, headerClassName: 'header-cell' },
        // { field: 'createdAt', headerName: 'تاريخ الإنشاء', flex: 0.1, headerClassName: 'header-cell', valueFormatter: formatCreatedAt, },
        {
            field: 'actions', headerName: 'Actions', flex: 0.1, renderCell: (params) => (
                <>
                    <div className='table-icon'>
                        <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                    </div>
                    <div className='table-icon' style={{ marginRight: "10px", backgroundColor: '#8bc34a' }}>
                        <SortIcon className='iconTable' onClick={() => handleShowActionClick(params.row, '/houdix/seen/admin/sub-subjects/', '/houdix/seen/admin/sub-subjects/sort', 'التصنيفات')} />
                    </div>
                    <div className='table-icon' style={{ marginRight: "10px", backgroundColor: '#f44336' }}>
                        <SortIcon className='iconTable' onClick={() => handleShowActionClick(params.row, '/houdix/seen/admin/previous/', '/houdix/seen/admin/previous/sort', 'الدورات')} />
                    </div>
                </>
            ),
            headerClassName: 'header-cell',
        },
    ];
}
//* for subSubject2 page
export const subSubject2DataGrid = (ModeEditIcon, handleEditActionClick, handleShowActionClick, SortIcon) => {
    return [
        { field: 'id', headerName: 'id', flex: 0.03, headerClassName: 'header-cell' },
        { field: 'sub_subject_name', headerName: 'الاسم', flex: 0.1, headerClassName: 'header-cell' },
        // { field: 'sub_subject_notes', headerName: 'ملاحظات', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject.subject_name', headerName: 'المادة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'parent.sub_subject_name', headerName: 'تصنيف رئيسي', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject.year.collage.university.university_name', headerName: 'الجامعة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject.year.collage.collage_name', headerName: 'الكلية', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject.year.year_name', headerName: 'السنة', flex: 0.1, headerClassName: 'header-cell' },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.1,
            renderCell: (params) => (
                <>
                    <div className='table-icon'>
                        <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                    </div>
                    <div className='table-icon' style={{ marginRight: "10px", backgroundColor: '#f44336' }}>
                        <SortIcon className='iconTable' onClick={() => handleShowActionClick(params.row, '/houdix/seen/admin/questions/sub-subject/', '/houdix/seen/admin/questions/sort', 'الاسئلة')} />
                    </div>
                </>
            ),
            headerClassName: 'header-cell',
        },
    ];
}
//* for subSubject page
export const subSubjectDataGrid = (ModeEditIcon, handleEditActionClick, handleShowActionClick, SortIcon) => {
    return [
        { field: 'id', headerName: 'id', flex: 0.03, headerClassName: 'header-cell' },
        { field: 'sub_subject_name', headerName: 'الاسم', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'sub_subject_notes', headerName: 'الملاحظة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject.subject_name', headerName: 'المادة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject.year.collage.university.university_name', headerName: 'الجامعة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject.year.collage.collage_name', headerName: 'الكلية', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject.year.year_name', headerName: 'السنة', flex: 0.1, headerClassName: 'header-cell' },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.1,
            renderCell: (params) => (
                <>
                    <div className='table-icon'>
                        <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                    </div>
                    <div className='table-icon' style={{ marginRight: "10px", backgroundColor: '#f44336' }}>
                        <SortIcon className='iconTable' onClick={() => handleShowActionClick(params.row, '/houdix/seen/admin/sub-subjects/sub/', '/houdix/seen/admin/sub-subjects/sort', 'تصنيفات فرعية')} />
                    </div>
                </>

            ),
            headerClassName: 'header-cell',
        },
    ];
}
//* for student page
export const studentDataGrid = (ImportExportIcon, handleEditActionClick, handleShowActionClick) => { 
    return [ 
        { field: 'id', headerName: 'id', flex: 0.1, headerClassName: 'header-cell' }, 
        { field: 'name', headerName: 'الاسم', flex: 0.1, headerClassName: 'header-cell' }, 
        { 
            field: 'image', headerName: 'الصورة', flex: 0.1, renderCell: (params) => ( 
                <div className='table-image'> 
                    {/* <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} /> */} 
                    <img style={{ width: "100%", cursor: "pointer" }} src={`${process.env.REACT_APP_URL}/${params.row.image}`} onClick={() => handleShowActionClick(params.row)} alt="" /> 
                </div> 
            ), 
            headerClassName: 'header-cell', 
        }, 
        { 
            field: 'test', headerName: 'questions', flex: 0.1, renderCell: (params) => ( 
                <Link
                    style={{ textDecoration: "none" }} 
                    to={`questions/${params.row.id}`} 
                > 
                    <div className='table-icon' style={{ width: "60px", borderRadius: "20px" }}> 
                        الاسئلة 
                    </div> 
                </Link> 
            ), 
            headerClassName: 'header-cell', 
        }, 
        { 
            field: 'actions', headerName: 'Actions', flex: 0.1, renderCell: (params) => ( 
                <div className='table-icon'> 
                    <ImportExportIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} /> 
                </div> 
            ), 
            headerClassName: 'header-cell', 
        }, 
    ]; 
}
//* for question page
export const questionDataGrid = (ModeEditIcon, handleEditActionClick, formatCreatedAt) => {
    return [
        { field: 'id', headerName: 'ID', flex: 0.03, headerClassName: 'header-cell' },
        { field: 'question_content', headerName: 'السؤال', flex: 0.3, headerClassName: 'header-cell' },
        { field: 'previous', headerName: 'الدورة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'sub_subject_questions', headerName: 'التصنيف', flex: 0.2, headerClassName: 'header-cell' },
        { field: 'subjects', headerName: 'المادة', flex: 0.1, headerClassName: 'header-cell' },
        // { field: 'createdAt', headerName: 'تاريخ الإنشاء', flex: 0.1, headerClassName: 'header-cell', valueFormatter: formatCreatedAt, },
        {
            field: 'actions', headerName: 'Actions', flex: 0.1, renderCell: (params) => (
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
    ];
}
//* for link page
export const linkDataGrid = (ModeEditIcon, handleEditActionClick, formatCreatedAt) => {
    return [
        // { field: 'id', headerName: 'ID', flex: 0.2, headerClassName: 'header-cell' },
        { field: 'url', headerName: 'الرابط', flex: 0.2, headerClassName: 'header-cell' },
        { field: 'description', headerName: 'وصف', flex: 0.2, headerClassName: 'header-cell' },
        // { field: 'createdAt', headerName: 'تاريخ الإنشاء', flex: 0.2, headerClassName: 'header-cell', valueFormatter: formatCreatedAt, },
        {
            field: 'actions', headerName: 'Actions', flex: 0.2, renderCell: (params) => (
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
    ];
}

//* for course page
export const courseDataGrid = (ModeEditIcon, handleEditActionClick) => {
    return [
        { field: 'id', headerName: 'ID', flex: 0.03, headerClassName: 'header-cell' },
        { field: 'previous_name', headerName: 'الاسم', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'previous_notes', headerName: 'الملاحظة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject.subject_name', headerName: 'المادة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject.year.collage.university.university_name', headerName: 'الجامعة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject.year.collage.collage_name', headerName: 'الكلية', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'subject.year.year_name', headerName: 'السنة', flex: 0.1, headerClassName: 'header-cell' },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.1,
            renderCell: (params) => (
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
    ];
}

//* for coupon page
export const couponDataGrid = (ModeEditIcon, handleEditActionClick) => {
    return [
        { field: 'coupon_content', headerName: 'الكوبون', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'coupon_notes', headerName: 'ملاحظة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'expiry_time', headerName: 'المدة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'limit', headerName: 'الحد', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'stay', headerName: 'المتبقي', flex: 0.1, headerClassName: 'header-cell' },
        {
            field: 'is_active', headerName: 'مفعل', flex: 0.1, headerClassName: 'header-cell', renderCell: (params) => (
                params.row.is_active === true ? "مفعل" : "غير مفعل"
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.1,
            renderCell: (params) => (
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
    ];
}

//* for collage page
export const collageDataGrid = (ModeEditIcon, handleEditActionClick, formatCreatedAt) => {
    return [
        // { field: 'id', headerName: 'ID', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'collage_name', headerName: 'اسم الكلية', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'university.university_name', headerName: 'الجامعة', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'university.university_state', headerName: 'المحافظة', flex: 0.1, headerClassName: 'header-cell' },
        // { field: 'createdAt', headerName: 'تاريخ الإنشاء', flex: 0.1, headerClassName: 'header-cell', valueFormatter: formatCreatedAt, },
        {
            field: 'actions', headerName: 'Actions', flex: 0.1, renderCell: (params) => (
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
    ];
}

//* for bachelor page
export const bachelorDataGrid = (ModeEditIcon, handleEditActionClick, formatCreatedAt) => {
    return [
        // { field: 'id', headerName: 'ID', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'bachelor_name', headerName: 'الفرع', flex: 0.1, headerClassName: 'header-cell' },
        // { field: 'createdAt', headerName: 'تاريخ الإنشاء', flex: 0.1, headerClassName: 'header-cell', valueFormatter: formatCreatedAt, },
        {
            field: 'actions', headerName: 'Actions', flex: 0.1, renderCell: (params) => (
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
    ];
}

//* for photo page
export const photoDataGrid = (formatCreatedAt, ModeEditIcon, handleEditActionClick, handleShowActionClick) => {
    return [
        { field: 'id', headerName: 'ID', flex: 0.01, headerClassName: 'header-cell' },
        { field: 'url', headerName: 'الرابط', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'text', headerName: 'النص', flex: 0.2, headerClassName: 'header-cell' },
        {
            field: 'actions', headerName: 'الصورة', flex: 0.04, renderCell: (params) => (
                <div className='table-image'>
                    {/* <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} /> */}
                    <img style={{ width: "100%", cursor: "pointer" }} src={`${process.env.REACT_APP_URL}/${params.row.url}`} onClick={() => handleShowActionClick(params.row)} alt="" />
                </div>
            ),
            headerClassName: 'header-cell',
        },
        // {
        //     field: 'actioons', headerName: 'Action', flex: 0.1, renderCell: (params) => (
        //         <div className='table-icon'>
        //             <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
        //         </div>
        //     ),
        //     headerClassName: 'header-cell',
        // },
    ];
}

//* for code page
export const codeDataGrid = (ModeEditIcon, handleEditActionClick) => {
    return [
        { field: 'id', headerName: 'id', flex: 0.05, headerClassName: 'header-cell' },
        { field: 'content', headerName: 'السؤال', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'category', headerName: 'الصنف', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer1', headerName: 'جواب1', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer2', headerName: 'جواب2', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer3', headerName: 'جواب3', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer4', headerName: 'جواب4', flex: 0.1, headerClassName: 'header-cell' },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.1,
            renderCell: (params) => (
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
    ];
}

export const questionsDataGrid = (ModeEditIcon, handleEditActionClick) => {
    return [
        { field: 'id', headerName: 'id', flex: 0.05, headerClassName: 'header-cell' },
        { field: 'content', headerName: 'السؤال', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'showed', headerName: 'الاستخدام', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'category', headerName: 'الصنف', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer1', headerName: 'جواب1', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer2', headerName: 'جواب2', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer3', headerName: 'جواب3', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer4', headerName: 'جواب4', flex: 0.1, headerClassName: 'header-cell' },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.1,
            renderCell: (params) => (
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
    ];
}

export const audienceDataGrid = (ModeEditIcon, handleEditActionClick) => {
    return [
        { field: 'id', headerName: 'id', flex: 0.05, headerClassName: 'header-cell' },
        { field: 'content', headerName: 'السؤال', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'showed', headerName: 'الاستخدام', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer1', headerName: 'جواب1', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer2', headerName: 'جواب2', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer3', headerName: 'جواب3', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer4', headerName: 'جواب4', flex: 0.1, headerClassName: 'header-cell' },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.1,
            renderCell: (params) => (
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
    ];
}

export const codeDataGrid2 = (ModeEditIcon, handleEditActionClick) => {
    return [
        { field: 'id', headerName: 'id', flex: 0.05, headerClassName: 'header-cell' },
        { field: 'content', headerName: 'السؤال', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'showed', headerName: 'الاستخدام', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer1', headerName: 'جواب1', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer2', headerName: 'جواب2', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer3', headerName: 'جواب3', flex: 0.1, headerClassName: 'header-cell' },
        { field: 'answer4', headerName: 'جواب4', flex: 0.1, headerClassName: 'header-cell' },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.1,
            renderCell: (params) => (
                <div className='table-icon'>
                    <ModeEditIcon className='iconTable' onClick={() => handleEditActionClick(params.row)} />
                </div>
            ),
            headerClassName: 'header-cell',
        },
    ];
}

export const studentHeaders = [
    { label: "question", key: "question.question_content" },
    { label: "answer 1", key: "stats[0].answer.answer_content" },
    { label: "count 1", key: "stats[0].counts.count" },
    { label: "answer 2", key: "stats[1].answer.answer_content" },
    { label: "count 2", key: "stats[1].counts.count" },
    { label: "answer 3", key: "stats[2].answer.answer_content" },
    { label: "count 3", key: "stats[2].counts.count" },
    { label: "answer 4", key: "stats[3].answer.answer_content" },
    { label: "count 4", key: "stats[3].counts.count" },
    { label: "answer 5", key: "stats[4].answer.answer_content" },
    { label: "count 5", key: "stats[4].counts.count" },
    { label: "corrects", key: "stats[3].counts.corrects" },
    { label: "wrongs", key: "stats[3].counts.wrongs" },
];

export const couponHeaders = [
    { label: 'id', key: 'id' },
    { label: 'coupon content', key: 'coupon_content' },
    { label: 'coupon notes', key: 'coupon_notes' },
    { label: 'expiry time', key: 'expiry_time' },
    { label: 'active', key: 'is_active' },
    { label: 'limit', key: 'limit' },
    { label: 'subjects', key: 'subjects' },
];

export const codeHeaders = [
    { label: 'id', key: 'id' },
    { label: 'code content', key: 'code_content' },
    { label: 'code note', key: 'code_notes' },
    { label: 'code name', key: 'code_name' },
    { label: 'expiry time', key: 'expiry_time' },
    { label: 'active', key: 'is_active' },
    { label: 'user name', key: 'user_name' },
    { label: 'user email', key: 'user_email' },
    { label: 'user phone', key: 'user_phone' },
    { label: 'user type', key: 'user_type' },
    { label: 'subjects', key: 'subjects' },
];

export const subSubjectHeaders = [
    { label: 'id', key: 'id' },
    { label: 'sub subject name', key: 'sub_subject_name' },
    { label: 'sub subject notes', key: 'sub_subject_notes' },
    { label: 'subject name', key: 'subject.subject_name' },
    { label: 'year name', key: 'subject.year.year_name' },
    { label: 'collage name', key: 'subject.year.collage.collage_name' },
    { label: 'university name', key: 'subject.year.collage.university.university_name' },
    { label: 'university state', key: 'subject.year.collage.university.university_state' },
    { label: 'bachelor name', key: 'subject.bachelor.bachelor_name' }
];

export const subSubject2Headers = [
    { label: 'id', key: 'id' },
    { label: 'sub subject name', key: 'sub_subject_name' },
    { label: 'sub subject notes', key: 'sub_subject_notes' },
    { label: 'subject name', key: 'subject.subject_name' },
    { label: 'year name', key: 'subject.year.year_name' },
    { label: 'collage name', key: 'subject.year.collage.collage_name' },
    { label: 'university name', key: 'subject.year.collage.university.university_name' },
    { label: 'university state', key: 'subject.year.collage.university.university_state' },
    { label: 'bachelor name', key: 'subject.bachelor.bachelor_name' },
    { label: 'parent sub_subject name', key: 'parent.sub_subject_name' },
];

export const courseHeaders = [
    { label: 'id', key: 'id' },
    { label: 'previous name', key: 'previous_name' },
    { label: 'previous notes', key: 'previous_notes' },
    { label: 'subject name', key: 'subject.subject_name' },
    { label: 'year name', key: 'subject.year.year_name' },
    { label: 'collage name', key: 'subject.year.collage.collage_name' },
    { label: 'university name', key: 'subject.year.collage.university.university_name' },
];