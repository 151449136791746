import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { useCookies } from 'react-cookie';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: "50px",
    // height: '500px',
    textAlign: 'center',
    boxShadow: 24,
    p: '50px 20px',


};

export default function ShowPhoto({ handleClose, handleClickAlert, setMessage, selectedRows }) {

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [question, setQuestion] = React.useState(true)

    const [formData, setFormData] = React.useState(null);

    const [question_id, setQuestion_id] = React.useState({
        question_id: null,
        answer_id: null,
    });

    const [isLoading, setIsLoading] = React.useState(false);

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const [data, setData] = React.useState({
        questions: [],
        answers: [],
    });


    const handleInputChange = (e) => {
        setFormData(e.target.files[0]);
    };

    const handleChange1 = (e, string) => {
        const { id } = e.target;
        setQuestion_id({
            ...question_id,
            [id.slice(0, 11)]: string.id
        })
    };
    const handleChange2 = (e, string) => {
        const { id } = e.target;
        setQuestion_id({
            ...question_id,
            [id.slice(0, 9)]: string.id
        })
    };

    React.useEffect(() => {
        const fetchProvinces = async (setData, url) => {
            const token = cookies['token']
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces(setData, `${process.env.REACT_APP_URL}/houdix/seen/admin/get-all-for-photo`);
    }, []);

    const handleFormSubmit = async (e) => {
        const token = cookies['token'];
        setIsLoading(true);
        e.preventDefault();
        const form = new FormData();
        form.append('image', formData);
        form.append('question_id', question_id.question_id);
        form.append('answer_id', question_id.answer_id);
        console.log(form);
        setIsSubmitting(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/houdix/seen/admin/photos`, form, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setIsLoading(false);
                setMessage("تمت العملية بنجاح")
                handleClose()
                handleClickAlert()
            }
        } catch (error) {
            console.log(error);
            setMessage("خطا في العملية")
            handleClose()
            handleClickAlert()
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!isLoading ? (
                        <div>
                            <img style={{ width: "100%" }} src={`${process.env.REACT_APP_URL}/${selectedRows.image}`} alt="" />
                        </div>
                    ) : (
                        <div style={{ display: 'flex', height: "300px", justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}