import axios from "axios";
import moment from "moment";


export const formatCreatedAt = (params) => {
    return moment(params.value).format('DD/MM/YYYY');
};

//* for fetch data at the beginning
export const fetchData = async (url, setRows, setIsLoading, setCsvRows) => {
    try {
        const response = await axios.get(url);
        setRows(response.data);
        setIsLoading(false);
        if (setCsvRows) {
            setCsvRows(response.data)
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

//* for deleting records
export const handleDeleteActionClick = (selectedRows, setMessage, handleClickAlert, messages, url) => {
    if (selectedRows.length === 0) {
        alert(messages.a);
        return;
    }
    const confirmation = window.confirm(messages.c);
    if (confirmation) {
        axios
            .delete(url, {
                data: { ids: selectedRows },
            })
            .then((response) => {
                if (response.status === 200) {
                    setMessage("تمت العملية بنجاح")
                    handleClickAlert()
                }
            })
            .catch((error) => {
                setMessage(messages.b);
                handleClickAlert()
            });
    }
};

export const fetchProvinces = async (setData, url) => {
    try {
        const response = await axios.get(url);
        setData(response.data);
    } catch (error) {
        console.error('Error fetching provinces:', error);
    }
};

export const handleAddOpen = (setAddOpen) => setAddOpen(true);
export const handleAddClose = (setAddOpen) => setAddOpen(false);
export const handleEditOpen = (setEditOpen) => setEditOpen(true);
export const handleEditClose = (setEditOpen) => setEditOpen(false);
