import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { useCookies } from 'react-cookie';
import { saveAs } from 'file-saver';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: "50px",
    // height: '500px',
    textAlign: 'center',
    boxShadow: 24,
    p: '50px 20px',


};

export default function AddCode({ handleClose, handleClickAlert, setMessage }) {

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [formData, setFormData] = React.useState(null);

    const [isLoading, setIsLoading] = React.useState(false);


    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const handleInputChange = (e) => {
        setFormData(e.target.files[0]);
    };

    const handleFormSubmit = async (e) => {
        const token = cookies['token'];
        setIsLoading(true);
        e.preventDefault();
        const form = new FormData();
        form.append('file', formData);
        setIsSubmitting(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/questions/excel/export`, form, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    // 'Content-Type': 'multipart/form-data' // Ensure proper content type
                },
                responseType: 'blob'
            });
            if (response.status === 200) {
                setIsLoading(false);
                setMessage("تمت العملية بنجاح");
                
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                
                saveAs(blob, 'أسئلة.xlsx');
                
                handleClose();
                handleClickAlert();
            }
        } catch (error) {
            console.log(error);
            setMessage("خطا في العملية")
            handleClose()
            handleClickAlert()
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!isLoading ? (
                        <>
                            <Typography sx={{ fontFamily: 'Almarai' }} id="modal-modal-title" variant="h3" component="h2">
                                هل أنت متأكد ؟
                            </Typography>
                            <div className="center">
                                <form onSubmit={handleFormSubmit}>
                                    {/* <div className="inputbox">
                                        <input
                                            type="file"
                                            name="file"
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div> */}
                                    <div className="inputbox" style={{ marginTop: '50px' }}>
                                        <button type="submit" disabled={isSubmitting}>
                                            موافق
                                        </button>
                                        <button type="button" onClick={handleClose}>
                                            إلغاء
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', height: "300px", justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}