export const items = [
    {
        name: "الاصناف",
        url: ''
    },
    {
        name: "الاسئلة",
        url: '/questions'
    },
    {
        name: "اسئلة السرعة",
        url: '/turbo'
    },
    {
        name: "اسئلة الجمهور",
        url: '/audience'
    },
    {
        name: 'الاعلانات',
        url: '/adversment'
    },
    // {
    //     name: "الكليات",
    //     url: '/collages'
    // },
    // {
    //     name: "السنوات",
    //     url: '/years'
    // },
    // {
    //     name: "بكالوريا",
    //     url: '/bachelors'
    // },
    // {
    //     name: "المواد",
    //     url: '/subjects'
    // },
    // {
    //     name: "الدورات",
    //     url: '/courses'
    // },
    // {
    //     name: "تصنيفات أساسية",
    //     url: '/sub-subjects'
    // },
    // {
    //     name: "تصنيفات فرعية",
    //     url: '/sub-subjects2'
    // },
    // {
    //     name: "الأسئلة",
    //     url: '/questions'
    // },
    // {
    //     name: "الروابط",
    //     url: '/links'
    // },
    // {
    //     name: "الفصل",
    //     url: '/chapters'
    // },
    // {
    //     name: "الصور",
    //     url: '/photos'
    // }
]
